import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Title from "./_shared/Title";
import SelectButtons from "./_shared/SelectButtons";
import FormButtons from "./_shared/FormButtons";
import {RaportDL} from '../_shared/DataLayer';

const FormStep1 = ({step, setStep, data, setData}) => {
  const {t} = useTranslation();
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const firstYear = currentYear - 11;
  const years = [];
  const optionsList = [];
  const monthsArray = [];
  const monthsConfig =  t('months', { returnObjects: true }).map(({shortName}) => (shortName));

  for (var i = firstYear; i <= currentYear; i++) {
    years.push(i);
  }

  years.forEach(year => {
    optionsList.push({ label: year, value: year})
  });

  monthsConfig.forEach((month, i) => {
    monthsArray.push({label: month, value: i + 1})
  });

  const handleChangeYear = ({ target }) => {
    if (Number(target.value) === currentYear) {
      setData({
        type: 'UPDATE_REGISTER_MONTHS',
        payload: {
          months: monthsConfig.slice(0, currentMonth + 1),
          optionsMonths: monthsArray.slice(0, currentMonth + 1),
        },
      });
    } else {
      setData({
        type: 'UPDATE_REGISTER_MONTHS',
        payload: {
          months: monthsConfig,
          optionsMonths: monthsArray,
        },
      });
    }

    setData({
      type: 'UPDATE_CAR_DATA',
      payload: { year: target.value },
    });

    setData({
      type: 'CHANGE_YEAR'
    });

    RaportDL('registration-month', 'step-2');
  };

  const handleChangeMonth = ({ target }) => {
    const index = target.dataset.index;

    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        month: target.value,
        monthIndex: index
      },
    });

    setData({
      type: 'CHANGE_MONTH'
    });

    setData({
      type: 'UPDATE_NEXT_BUTTON',
      payload: {
        buttonStep1: true
      },
    });

    RaportDL('brand', 'step-3');

    setStep(step + 1);
  };

  const handleChangeYearSelect = (e) => {
    if (e.value === currentYear) {
      setData({
        type: 'UPDATE_REGISTER_MONTHS',
        payload: {
          months: monthsConfig.slice(0, currentMonth + 1),
          optionsMonths: monthsArray.slice(0, currentMonth + 1),
        },
      });
    } else {
      setData({
        type: 'UPDATE_REGISTER_MONTHS',
        payload: {
          months: monthsConfig,
          optionsMonths: monthsArray,
        },
      });
    }

    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        year: e.value
      }
    });

    setData({
      type: 'CHANGE_YEAR'
    });
  };

  const handleChangeMonthSelect = (e) => {
    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        month: e.label,
        monthIndex: e.value
      },
    });

    setData({
      type: 'CHANGE_MONTH'
    });

    setData({
      type: 'UPDATE_NEXT_BUTTON',
      payload: {
        buttonStep1: true
      },
    });

    setStep(step + 1);
  };

  const validHandler = () => {
    if (!data.carData.year || !data.carData.month) {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: true
        },
      });
    } else {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: false
        },
      });
      setStep(step + 1);
    }
  };

  return (
      <>
        <div>
          <Title title={t('formTitleYearOfRegistration')} />
          <SelectButtons blockButtons={years} selectOptions={optionsList} radioName='yearRadio' callback={handleChangeYear} callbackSelect={handleChangeYearSelect} checkedField={data.carData.year} selectCheckedField={data.carData.year ? {"label": data.carData.year, "value": data.carData.year} : {"label": t('selectPlaceholder'), "value": ""}} />
        </div>
        {data.carData.year &&
        <div>
          <Title title={t('formTitleMonthOfRegistration')} />
          <SelectButtons blockButtons={data.registerMonths.months} selectOptions={data.registerMonths.optionsMonths} radioName='monthRadio' callback={handleChangeMonth} callbackSelect={handleChangeMonthSelect} checkedField={data.carData.month} selectCheckedField={data.carData.month ? {"label": data.carData.month} :{"label": t('selectPlaceholder'), "value": ""}} />
        </div>
        }
        <FormButtons next={validHandler} nextActive={data.stepsNextButtons.buttonStep1} prev={() => window.location.href = "/"} error={data.error} />
      </>
  )
};

FormStep1.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormStep1;
