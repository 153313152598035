import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {RaportDL} from '../_shared/DataLayer';
import PropTypes from 'prop-types';

const DealerBoxWrapper = styled.li`
  display: flex;
  padding: 16px 8px;
  border-bottom: 1px solid ${props => props.theme.colors.darkWhite};
  color: ${props => props.theme.colors.black};
  
  &:hover {
    background-color: ${props => props.theme.colors.darkWhite};
  }
  
  &.is-active {
    background-color: ${props => props.theme.colors.darkWhite};
  }
`;

const DealerBoxPinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
  min-width: 35px;
`;

const DealerBoxPin = styled.div`
  position: relative;
  display: inline-block;
  z-index: 1;
`;

const DealerBoxPinElement = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-bottom: 16px;
  border: 0 solid;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.brandColor};
  
  &:after {
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 0;
    height: 0;
    content: "";
    z-index: -1;
    border-style: solid;
    border-width: 16px 11px 0;
    transform: translate3d(-50%, 0, 0);
    transition: border-color .5s ease;
    border-color: ${props => props.theme.colors.brandColor} transparent transparent;
  }
`;

const DealerBoxPinElementText = styled.div`
  color: ${props => props.theme.colors.white};
  font-family: 'ReadMedium';
  font-size: 13px;
  line-height: 18px;
`;

const DealerBoxDistance = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 17px;
  font-family: 'ReadMedium';
`;

const DealerBoxDistanceNumber = styled.span`
  font-family: 'ReadMedium';
`;

const DealerBoxInfo = styled.div`
  flex: 1;
`;

const DealerBoxInfoAddress = styled.div`
  display: flex;
`;

const DealerBoxInfoAddressBox = styled.div`
  flex: 2;
  margin-right: 8px;
`;

const DealerBoxInfoAddressBoxName = styled.div`
  margin: 4px 0;
  color: ${props => props.theme.colors.brandColor};
  font-family: 'ReadMedium';
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
`;

const DealerBoxInfoAddressBoxAddress = styled.p`
  margin-top: 12px;
  font-family: 'ReadLight';
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
`;

const DealerBoxInfoAddressBoxPhone = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 17px;
`;

const DealerBoxInfoAddressBoxPhoneLink = styled.a`
  color: ${props => props.theme.colors.brandColor};
  font-family: 'ReadMedium';
  text-decoration: underline;
`;

const DealerBoxInfoAddressBoxBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
`;

const DealerBoxInfoAddressBoxBtn = styled.button`
  display: inline-flex;
  align-items: center;
  min-width: 100px;
  min-height: 40px;
  margin-top: 8px;
  padding: 8px;
  font-family: ReadMedium;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.black};
  transition: background-color 0.5s ease 0s, color 0.5s ease 0s, border-color 0.5s ease 0s, color 0.5s ease 0s;
  
  &:hover {
     color: ${props => props.theme.colors.white};
     background-color: ${props => props.theme.colors.black};
     cursor: pointer;
  }
`;

const DealerContact = styled.div`
  margin-top: 8px;
`;

const DealerContactSvg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 22px;
  transform: translate(-50%, -50%);
  fill: ${props => props.theme.colors.black};
`;

const DealerContactLink = styled.a`
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  margin: 4px;
  padding: 16px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.black};
  transition: background-color 0.5s ease 0s, color 0.5s ease 0s, border-color 0.5s ease 0s, color 0.5s ease 0s;
  
  &:hover {
     color: ${props => props.theme.colors.white};
     background-color: ${props => props.theme.colors.black};
     
     ${DealerContactSvg} {
      fill: ${props => props.theme.colors.white};
     }
  }
`;

const AddressLink = styled.a`
  color: ${props => props.theme.colors.black};;
  text-decoration: none;
`;

const DealerBox = ({i, id, name, street, postalCode, city, phoneNumber, distance, activeDealer, setDealer, lat, lng, step, setStep, data, setData, hoverFunc}) => {
  const { t } = useTranslation();

  const setDealerHandler = (e, id) => {
    e.preventDefault();
    setData({
      type: 'UPDATE_DEALER_DATA',
      payload: {
        dealer: id
      },
    });

    RaportDL('offer', 'step-11');

    setStep(step + 1);
  };

  return (
      <DealerBoxWrapper id={id} className={(Number(activeDealer) === Number(id)) ? 'is-active' : ''} onMouseEnter={hoverFunc}>
        <DealerBoxPinWrapper>
          <DealerBoxPin>
            <DealerBoxPinElement>
              <DealerBoxPinElementText>{i + 1}</DealerBoxPinElementText>
            </DealerBoxPinElement>
          </DealerBoxPin>
          <DealerBoxDistance>
            <DealerBoxDistanceNumber>{(distance/1000).toFixed(1)}</DealerBoxDistanceNumber> km
          </DealerBoxDistance>
        </DealerBoxPinWrapper>
        <DealerBoxInfo>
          <DealerBoxInfoAddress>
            <DealerBoxInfoAddressBox>
              <DealerBoxInfoAddressBoxName>{name}</DealerBoxInfoAddressBoxName>
              <AddressLink href={`https://www.google.com/maps/place/${street} ${postalCode} ${city}`} target="_blank">
                <DealerBoxInfoAddressBoxAddress>{street} <br /> {postalCode} {city}</DealerBoxInfoAddressBoxAddress>
              </AddressLink>
              <DealerBoxInfoAddressBoxPhone>
                <DealerBoxInfoAddressBoxPhoneLink href={`tel:${phoneNumber}`}>
                  {t('phoneShort')} {phoneNumber}
                </DealerBoxInfoAddressBoxPhoneLink>
              </DealerBoxInfoAddressBoxPhone>
            </DealerBoxInfoAddressBox>
            <DealerBoxInfoAddressBoxBtnWrapper>
              <DealerBoxInfoAddressBoxBtn onClick={(e) => {setDealerHandler(e, id);}}>
                {t('chooseDealer')}
              </DealerBoxInfoAddressBoxBtn>
            </DealerBoxInfoAddressBoxBtnWrapper>
          </DealerBoxInfoAddress>
          <div></div>
          <DealerContact>
            <DealerContactLink href={`https://www.google.com/maps/dir/${lat},${lng}`} target="_blank">
              <DealerContactSvg viewBox="0 0 30 30">
                <path d="M17.996 11.316c0-1.842-1.156-3-2.996-3s-2.996 1.157-2.996 3c0 1.841 1.156 3 2.996 3s2.996-1.158 2.996-3m1.788 0c0 2.684-2.05 4.738-4.784 4.738S10.216 14 10.216 11.316c0-2.738 2.05-4.738 4.784-4.738s4.784 2.001 4.784 4.738m4.574-.526c0-5.316-3.785-9-9.358-9s-9.358 3.684-9.358 9c0 4.789 3.523 11.42 9.358 17.474 5.835-6.054 9.358-12.685 9.358-17.474m-20.608 0C3.75 4.318 8.271 0 15 0s11.25 4.316 11.25 10.79c0 5.315-3.995 12.737-10.304 19.21h-1.892C7.745 23.526 3.75 16.105 3.75 10.79"></path>
              </DealerContactSvg>
            </DealerContactLink>
          </DealerContact>
        </DealerBoxInfo>
      </DealerBoxWrapper>
  );
};

DealerBox.defaultProps = {
  i: 0,
  name: '',
  street: '',
  postalCode: '',
  city: '',
  phoneNumber: '',
  distance: '',
  activeDealer: 0
};

DealerBox.propTypes = {
  i: PropTypes.number,
  name: PropTypes.string,
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  phoneNumber: PropTypes.string,
  distance: PropTypes.number,
  activeDealer: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DealerBox;
