import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0%{width:0}50%{width:100%}to{width:0}
`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .4);
  
  &.nontransparent {
    background-color: rgba(255, 255, 255, 1);
  }
  
  &.is-active {
    display: block;
    transition: height 0s ease 0s, background .25s ease-in-out 0s;
  }
`;

const LoaderBelt = styled.div`
  position: relative;
  height: 2px;
`;

const LoaderBar = styled.div`
  position: absolute;
  left: 50%;
  width: 0;
  display: inline-block;
  height: 100%;
  content: "";
  transform: translateX(-50%);
  text-align: center;
  background-color: ${props => props.theme.colors.brandColor};
  animation: 1s linear 0s infinite normal none running ${loading};
`;

const Svg = styled.svg`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 180px;
  height: 100%;
  fill: ${props => props.theme.colors.black};
`;

const Loader = ({loaderClass, opacity}) => {
  let lClass = 'loader';

  if (loaderClass) {
    lClass += ' is-active';
  }

  if (opacity) {
    lClass += ' nontransparent';
  }

  return (
      <LoaderWrapper className={lClass}>
        <LoaderBelt className='loader-belt'>
            <LoaderBar className='loader-bar' />
        </LoaderBelt>
        {opacity &&
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 20">
          <path d="M127.109 19.62h7.678V0h-7.678v19.62zM88.459 1.06L80.62 9.01c-.28.302-.431.54-.431.8 0 .259.15.496.431.799l7.838 7.952c.82.821 1.382 1.059 2.59 1.059h25.823v-6.634H93.036L89.668 9.81l3.368-3.176h23.836V0H91.05c-1.21 0-1.77.238-2.591 1.059zM28.413 18.562l7.838-7.952c.28-.303.431-.54.431-.8 0-.259-.15-.497-.431-.8l-7.838-7.95C27.593.238 27.031 0 25.823 0H0v6.634h23.836l3.368 3.176-3.368 3.177H0v6.634h25.822c1.21 0 1.77-.238 2.591-1.059zm141.605 1.059H180L161.173.448c-.3-.299-.536-.448-.794-.448-.257 0-.494.15-.794.448L140.758 19.62h9.897l9.66-9.939 9.703 9.939zm-102.37 0h9.982L58.804.448c-.3-.299-.537-.448-.795-.448-.257 0-.493.15-.794.448L38.39 19.62h9.896l9.66-9.939 9.703 9.939z"></path>
        </Svg>
        }
      </LoaderWrapper>
  );
};

export default Loader;
