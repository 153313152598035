import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  padding: 18px 48px 14px 52px;
  color: ${props => props.theme.colors.lightBlack};
  font-size: 13px;
  line-height: 13px;
  font-family: 'ReadMedium';
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${props => props.theme.colors.darkWhite};
  border: 1px solid ${props => props.theme.colors.darkWhite};
  vertical-align: middle;
  transition: background-color 0.5s ease 0s, color 0.5s ease 0s, border-color 0.5s ease 0s, color 0.5s ease 0s;
  cursor: pointer;
  
  @media ${breakpoints.sm} {
    font-size: 14px;
    line-height: 16px;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.grayBtnHoverColor};
    border-color: ${props => props.theme.colors.grayBtnHoverColor};
  }
`;

const Svg = styled.svg`
  position: absolute;
  top: 50%;
  left: 32px;
  width: 14px;
  fill: ${props => props.theme.colors.black};
  transform: translateY(-50%);
`;

const LinkArrow = ({text, destination}) => {
  return (
      <StyledLink to={destination} className="link-arrow">
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.075 34.075" className="">
          <path d="M24.57 34.075a1.964 1.964 0 0 1-1.396-.577L8.11 18.432a1.972 1.972 0 0 1 0-2.79L23.174.578a1.973 1.973 0 1 1 2.791 2.79l-13.67 13.669 13.67 13.669a1.974 1.974 0 0 1-1.395 3.369z"></path>
        </Svg>
        {text}
      </StyledLink>
  );
};

LinkArrow.defaultProps = {
  destination: '/'
};

LinkArrow.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string
};

export default LinkArrow;
