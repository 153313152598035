import React from "react";
import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import { useTranslation } from "react-i18next";
import Benefits from './Benefits';
import MainBox from './MainBox';
import Disclaimer from './Disclaimer';
import Footer from '../_shared/Footer';
import Header from '../_shared/Header';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  
  @media ${breakpoints.lg} {
    min-height: calc(100vh - 168px);
  }
`;

const Section = styled.section`
  display: flex;
  min-height: 520px;
  flex: 1 1 0%;
`;

const Body = styled.div`
  position: relative;
  flex: 1 1 0%;
`;

const Picture = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.black};
  overflow: hidden;
`;

const Image = styled.img`
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  opacity: .65;
`;

const PageContent = styled.div`
  position: relative;
  max-width: ${props => props.theme.sizes.contentWidth};
  margin: 0 auto;
  padding: 16px;
  
  @media ${breakpoints.sm} {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
`;

const Title = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.white};
  font-size: 17px;
  font-family: 'DaciaBlock';
  line-height: 20px;
  text-align: center;
  
  @media ${breakpoints.sm} {
    flex-grow: 1;
    margin: 32px 16px;
    font-size: 22px;
    line-height: 25px;
  }
`;

const InnerContent = styled.div`
  @media ${breakpoints.sm} {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
`;

const LeftContent = styled.div`
  flex: 1;
  
  @media ${breakpoints.sm} {
    margin: 16px;
  }
`;

const RightContent = styled.div`
  flex-basis: calc(40% - 32px);
  margin-top: 32px;
  
  @media ${breakpoints.sm} {
    margin: 16px;
  }
`;

const img375 = process.env.PUBLIC_URL + '/mainpage/main-page-bg-375.webp';
const img640 = process.env.PUBLIC_URL + '/mainpage/main-page-bg-640.webp';
const img750 = process.env.PUBLIC_URL + '/mainpage/main-page-bg-750.webp';
const img1024 = process.env.PUBLIC_URL + '/mainpage/main-page-bg-1024.webp';
const img1280 = process.env.PUBLIC_URL + '/mainpage/main-page-bg-1280.webp';
const img2048 = process.env.PUBLIC_URL + '/mainpage/main-page-bg-2049.webp';
const img2560 = process.env.PUBLIC_URL + '/mainpage/main-page-bg-2560.webp';

const MainPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('header')} />
      <PageWrapper className="main-page">
        <Section className="main-page_section">
          <Body className="main-page_body">
            <Picture className="main-page_background">
              <source
                  srcSet={`${img375} 375w, ${img640} 640w, ${img750} 750w, ${img1024} 1024w, ${img1280} 1280w, ${img2048} 2048w, ${img2560} 2560w`}
                  type="image/webp" sizes="(min-width: 1024px) 1280px, (min-width: 640px) 100vw, 100vw" />
              <Image src={process.env.PUBLIC_URL + '/mainpage/main-page-bg-375.webp'} alt="background" className="PictureElement__imgDefault" />
              <noscript><img src={img375} alt="background" /></noscript>
            </Picture>
            <PageContent className="main-page_content">
              <Title className="main-page_title">
                {t('mainPageTitle')}
              </Title>
              <InnerContent className="main-page_inner-container">
                <LeftContent className="main-page_left">
                  <MainBox />
                </LeftContent>
                <RightContent className="main-page_right">
                  <Benefits />
                </RightContent>
              </InnerContent>
            </PageContent>
          </Body>
        </Section>
        <Disclaimer />
      </PageWrapper>
      <Footer />
    </>
  );
};

export default MainPage;
