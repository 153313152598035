import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const Button = styled(Link)`
  display: inline-block;
  width: calc(100% - 32px);
  padding: 18px 16px;
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  line-height: 16px;
  font-family: 'ReadMedium';
  text-align: center;
  text-decoration: none;
  background-color: ${props => props.theme.colors.brandColor};
  border: 1px solid ${props => props.theme.colors.brandColor};
  vertical-align: middle;
  transition: background-color 0.5s ease 0s, color 0.5s ease 0s, border-color 0.5s ease 0s, color 0.5s ease 0s;
  cursor: pointer;
  
  @media ${breakpoints.sm} {
    width: 90%;
    font-size: 14px;
    line-height: 14px;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.black};
    border-color: ${props => props.theme.colors.black};
  }
`;

const ButtonPrimary = ({text, destination, callback}) => {
  return (
      <Button className="button button--primary" to={destination} onClick={callback}>{text}</Button>
  );
};

ButtonPrimary.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default ButtonPrimary;
