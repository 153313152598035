import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints } from "../../../commons/breakpoints";
import Theme from '../../../commons/theme';
import {useTranslation} from "react-i18next";
import Select, { components } from 'react-select';

const Svg = styled.svg`
  width: 18px;
  height: 18px;
  margin-left: 9px;
  fill: ${props => props.theme.colors.brandColor};
  
  @media ${breakpoints.sm} {
    fill: ${props => props.theme.colors.lightBlack};
  }
`;

const SelectComponent = styled(Select)`
  @media ${breakpoints.sm} {
    // display: none;
  }
`;

const selectStyle = {
  control: styles => ({
    ...styles,
    marginBottom: 16,
    boxShadow: 'none',
    borderColor: '#646b52',
    borderRadius: 0,
    color: '#000',
    fontFamily: 'ReadMedium',
    fontSize: '16px',
    lineHeight: '18px',

    ':hover': {
      borderColor: '#646b52'
    },

    "@media only screen and (min-width: 768px)": {
      borderColor: '#ccc',
    },
  }),
  placeholder: styles => ({
    ...styles,
    color: '#000',
    fontFamily: 'ReadMedium',
    fontSize: '16px',
    lineHeight: '18px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#000',
    padding: 0
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '11px 30px'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    margin: 0,
    backgroundColor: '#646b52',

    "@media only screen and (min-width: 769px)": {
      backgroundColor: '#ccc',
    },
  }),
  indicatorsContainer: styles => ({
    ...styles,
    alignItems: 'center',
    width: 52
  }),
  option: (styles, {data, isSelected} )=> ({
    ...styles,
    border: '1px solid #fff',
    backgroundColor:
        isSelected ? '#ade1f5' : null
  })
};

const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
  return (
      <components.DropdownIndicator {...props}>
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className="SvgIcon">
          <path d="M1.822 7.527a1.058 1.058 0 0 0-1.46 1.532l14.384 13.707L29.624 9.071a1.059 1.059 0 0 0-1.432-1.558l-13.42 12.354-12.95-12.34z"></path>
        </Svg>
      </components.DropdownIndicator>
  );
};

const CustomSelect = ({selectOptions, callback, value}) => {
  const {t} = useTranslation();

  return (
      <Theme>
        <SelectComponent
            placeholder={t('selectPlaceholder')}
            components={{ DropdownIndicator }}
            options={selectOptions}
            styles={selectStyle}
            onChange={callback}
            defaultValue={value}
        />
      </Theme>
  );
};

CustomSelect.propTypes = {
  selectOptions: PropTypes.array.isRequired,
  callback: PropTypes.func
};

export default CustomSelect;
