import React from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import { breakpoints } from "../../../commons/breakpoints";
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  margin-bottom: 48px;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  
  @media ${breakpoints.sm} {
    margin-bottom: 40px;
  }
`;

const Svg = styled.svg`
  position: absolute;
  bottom: -6px;
  left: calc(50% - 6px);
  z-index: 2;
  display: none;
  height: 12px;
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  padding: 32px 8px 8px;
  counter-reset: step;
  
  @media ${breakpoints.sm} {
    padding: 5px 8px 8px;
  }
`;

const ListItem = styled.li`
  flex: 1 1 0%;
  position: relative;
  max-width: 180px;
  margin-bottom: 16px;
  color: ${props => props.theme.colors.stepperBorderColor};
  text-align: center;
  list-style: none;
  
  &:before {
      position: absolute;
      bottom: 0;
      left: -50%;
      content: "";
      width: 100%;
      border-bottom: 1px solid;
    }
  
  &:first-child {
    &:before {
      display: none;
    }
  }
  
  &:after {
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 18px;
    height: 18px;
    counter-increment: step;
    content: counter(step);
    border: 1px solid ${props => props.theme.colors.stepperBorderColor};
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.stepperBorderColor};
    font-family: 'ReadLight';
    font-size: 10px;
    border-radius: 50%;
  }
  
  &.is-active {
    font-family: 'ReadMedium';
    
    &:after {
      bottom: -12px;
      left: calc(50% - 12px);
      width: 24px;
      height: 24px;
      color: ${props => props.theme.colors.white};
      font-family: 'ReadMedium';
      background-color: ${props => props.theme.colors.brandColor};
      border: none;
    }
  }
  
  &.is-complete {
    &:after {
      color: ${props => props.theme.colors.darkWhite};
      border-color: ${props => props.theme.colors.darkWhite};
      background-color: ${props => props.theme.colors.darkWhite};
    }
    
    ${Svg} {
      display: block;
    }
  }
`;

const ListItemTitle = styled.span`
  display: none;
  padding-bottom: 16px;
  color: ${props => props.theme.colors.stepperBorderColor};
  font-family: 'ReadLight';
  font-size: 10px;
  line-height: 14px;
  
  @media ${breakpoints.sm} {
    display: inline-block;
  }
  
  &.is-active {
    color: ${props => props.theme.colors.black};
    font-family: 'ReadMedium';
  }
  
  .is-complete & {
    font-family: 'ReadMedium';
  }
`;

const ListAccommplish = styled.span`
  position: absolute;
  bottom: 0;
  left: -50%;
  border-bottom: 1px solid ${props => props.theme.colors.brandColor};

  &:after {
    position: absolute;
    right: 0;
    bottom: 6px;
    width: 0;
    height: 0;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    border-top-color: ${props => props.theme.colors.brandColor};
    transform: translateX(50%);
  }
`;

function Stepper({activeStep, accomplish, accomplishStep}) {
  const { t } = useTranslation();
  let accomplishStepTab;
  let accomplishPercent;

  if (accomplish) {
    accomplishStepTab = accomplish.split('/');
    accomplishPercent = ((accomplishStepTab[0] / accomplishStepTab[1]) * 100).toFixed(4);
  }

  const steps = t('stepperTitles', { returnObjects: true }).map(({title}, i) =>
      <ListItem className={i + 1 === Number(activeStep) ? 'stepper_list-item is-active' : (i + 1 < Number(activeStep)) ? 'stepper_list-item is-complete' : 'stepper_list-item'} key={i}>
        <ListItemTitle className={i + 1 === Number(activeStep) ? 'stepper_list-title is-active' : (i + 1 < Number(activeStep)) ? 'stepper_list-title is-complete' : 'stepper_list-title'}>{title}</ListItemTitle>
        {(accomplish && Number(accomplishStep) + 1 === i + 1) ? <ListAccommplish className='stepper_list-accomplish' style={{width: `${accomplishPercent}%`}} /> : null}
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
          <path d="M20.9 6.2h3c-3.1 6-6.2 12.3-9.3 17.5h-1.9c-2.7-3.3-5.1-7-7.1-10h3.1c1.5 2.3 3.2 4.6 4.8 6.8 2.8-5 5.4-9.9 7.4-14.3z" fillRule="evenodd"></path>
        </Svg>
      </ListItem>
  );

  return (
      <Wrapper className="stepper">
        <List className="stepper_list">
          {steps}
        </List>
      </Wrapper>
  );
}
Stepper.defaultProps = {
  activeStep: '1'
};

Stepper.propTypes = {
  activeStep: PropTypes.string.isRequired,
  accomplish: PropTypes.string,
  accomplishStep: PropTypes.string
};

export default Stepper;
