import React from 'react';
import styled from 'styled-components';
import { breakpoints } from "../../../commons/breakpoints";
import PropTypes from 'prop-types';
import Theme from '../../../commons/theme';

const Error = styled.span`
  position: static; 
  display: none;
  flex-basis: 100%;
  margin-top: 2px;
  color: ${props => props.theme.colors.errorColor};
  font-size: 10px;
  line-height: 14px;
  font-family: 'ReadLight';
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: 32px;
  
  @media ${breakpoints.sm} {
    margin-top: 0;
  }
  
  .contact-checkboxes & {
    margin-top: 12px;
  }
`;

const Svg = styled.svg`
  display: none;
  fill: ${props => props.theme.colors.black};
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  left: -9999px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:checked + & {
    ${Svg} {
      display: block;
    }
  }
`;

const LabelWrapper = styled.div`
  display: inline-block;
  width: calc(100% - 32px);
  margin-top: 38px;
  
  @media ${breakpoints.sm} {
    margin-top: 5px;
  }
  
  .contact-checkboxes & {
    margin-top: 16px;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 42px;
  font-family: 'ReadLight';
  
  &:hover {
    cursor: pointer
  }
  
  &.is-invalid {
    ${Error} {
      display: block;
    }
    
    ${LabelWrapper} {
      color: ${props => props.theme.colors.errorColor};
    }
  }
  
  .contact-checkboxes & {
    margin-top: 22px;
  }
`;

const Link = styled.a`
  font-family: 'ReadLight';
`;

const Checkbox = ({name, label, labelLink, labelLinkAnchor, labelStar, value, callback, errorText}) => {
  return (
      <Theme>
        <Label className={(errorText && errorText !== '') ? 'is-invalid' : ''}>
          <CheckboxContainer>
            <HiddenCheckbox  type='checkbox' name={name} checked={value} onChange={callback} />
            <StyledCheckbox>
              <Svg viewBox="0 0 24 24">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                  <path d="M29 9.182L23.91 4 12.03 16.092l-5.94-6.046L1 15.228l11.03 11.228z"></path>
                </svg>
              </Svg>
            </StyledCheckbox>
          </CheckboxContainer>
          <LabelWrapper>
            {label} {labelLink ? (<Link href={labelLink} target='_blank' rel='noopener noreferrer'>{labelLinkAnchor}</Link>) : ''} {labelStar ? '*' : ''}
          </LabelWrapper>
          {errorText &&
            <Error>{errorText}</Error>
          }
        </Label>
      </Theme>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelLink: PropTypes.string,
  labelLinkAnchor: PropTypes.string,
  labelStar: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default Checkbox;
