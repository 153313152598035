import React from 'react';
import styled from 'styled-components';
import { breakpoints } from "../../../commons/breakpoints";
import Theme from '../../../commons/theme';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import ButtonForm from '../../_shared/ButtonForm';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 48px 0 32px;
  
  @media ${breakpoints.sm} {
    margin-top: 64px;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const ErrorWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 100%;
  min-width: 200px;
  margin-bottom: 16px;
  padding: 16px;
  font-family: 'ReadLight';
  font-size: 14px;
  line-height: 20px;
  background-color: ${props => props.theme.colors.darkWhite};
  box-shadow: rgba(0, 0, 0, .22) 0 0 2px 0;
  border-left: 4px solid ${props => props.theme.colors.errorColor};
  
  &:before {
    position: absolute;
    top: 100%;
    right: 32px;
    width: 0;
    height: 0;
    content: "";
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid rgba(0, 0, 0, .15);
  }
  
  &:after {
    position: absolute;
    top: 100%;
    right: 32px;
    width: 0;
    height: 0;
    margin-right: 1px;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${props => props.theme.colors.darkWhite};
  }
`;

const FormButtons = ({next, nextActive, prev, validFunction, error}) => {
  const { t } = useTranslation();

  return (
      <Theme>
        <Wrapper className="buttons">
          <ButtonWrapper className="button-wrapper">
            <ButtonForm text={t('backButtonText')} step={prev} />
          </ButtonWrapper>
          <ButtonWrapper className="button-wrapper">
            <ButtonForm text={t('nextButtonText')} step={next} arrowPosition='right' validFunction={validFunction} activeClass={nextActive ? 'is-active' : ''}/>
            {error &&
              <ErrorWrapper className="button-error">
                {t('formValidErrorText')}
              </ErrorWrapper>
            }
          </ButtonWrapper>
        </Wrapper>
      </Theme>
  );
};

FormButtons.defaultProps = {
  error: false
};

FormButtons.propTypes = {
  next: PropTypes.func.isRequired,
  nextActive: PropTypes.bool.isRequired,
  prev: PropTypes.func.isRequired,
  error: PropTypes.bool
};

export default FormButtons;
