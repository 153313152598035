import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import { useTranslation } from "react-i18next";

const Wrapper = styled.footer`
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  margin-top: auto;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 8px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.black};
  
  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 8px 64px 16px;
    align-items: center;
  }
`;

const List = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 8px;
  
  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 32px 16px 0;
  }
`;

const ListElement = styled.li`
  position: relative;
  display: inline-block;
  padding: 4px 16px 4px 0;
  font-size: 12px;
  white-space: nowrap;
`;

const Link = styled.a`
  position: relative;
  padding: 0;
  color: ${props => props.theme.colors.white};
  font-family: "ReadLight";
  text-decoration: none;
    
  &:after {
    position: absolute;
     bottom: -2px;
     left: 0;
     width: 0;
     height: 1px;
     background-color: ${props => props.theme.colors.white};
     content: "";
     transition: all .2s;
  }
    
  &:hover {
   &:after {
    width: 100%;
  }
`;

const Copyright = styled.span`
  padding-top: 16px;
  padding-left: 8px;
  font-size: 10px;
  font-family: 'ReadMedium';
  text-transform: capitalize;
  
  @media ${breakpoints.sm} {
    padding-top: 0;
  }
`;

const Footer = () => {
  const now = new Date();
  const year = now.getFullYear();
  const { t } = useTranslation();
  const linksMap = t('footerLinks', { returnObjects: true }).map(({href, anchor}, i) =>
      <ListElement key={i}>
        <Link href={href} title={anchor.toString()} target="_blank" rel="noopener">
          {anchor}
        </Link>
      </ListElement>
  );

  return (
      <Wrapper>
        <Nav>
          <List>
            {linksMap}
          </List>
          <Copyright>
            © Renault 2017 - {year}
          </Copyright>
        </Nav>
      </Wrapper>
  );
};

export default Footer;
