import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import Theme from '../../commons/theme';
import PropTypes from "prop-types";

const Button = styled.button`
  position: relative;
  padding: 14px 32px 14px 56px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${props => props.theme.colors.brandColor};
  background-color: ${props => props.theme.colors.brandColor};
  transition: background-color .5s,color .5s,border-color .5s,color .5s;
  white-space: nowrap;
  
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.brandColor};
    border-color: ${props => props.theme.colors.brandColor};
  }
`;

const TextContainer = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-family: 'ReadMedium';
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
`;

const Svg = styled.svg`
  position: absolute;
  top: 50%;
  left: 26px;
  transform: translateY(-50%);
  width: 22px;
  fill: ${props => props.theme.colors.white};
`;

const ButtonSearchMap = ({onClickFunc}) => {
  const { t } = useTranslation();

  return (
      <Theme>
        <Button onClick={onClickFunc}>
          <Svg viewBox="0 0 30 30">
            <path d="M17.996 11.316c0-1.842-1.156-3-2.996-3s-2.996 1.157-2.996 3c0 1.841 1.156 3 2.996 3s2.996-1.158 2.996-3m1.788 0c0 2.684-2.05 4.738-4.784 4.738S10.216 14 10.216 11.316c0-2.738 2.05-4.738 4.784-4.738s4.784 2.001 4.784 4.738m4.574-.526c0-5.316-3.785-9-9.358-9s-9.358 3.684-9.358 9c0 4.789 3.523 11.42 9.358 17.474 5.835-6.054 9.358-12.685 9.358-17.474m-20.608 0C3.75 4.318 8.271 0 15 0s11.25 4.316 11.25 10.79c0 5.315-3.995 12.737-10.304 19.21h-1.892C7.745 23.526 3.75 16.105 3.75 10.79"></path>
          </Svg>
          <TextContainer>{t('dealerLocatorLocalisationBtnText')}</TextContainer>
        </Button>
      </Theme>
  );
};

ButtonSearchMap.propTypes = {
  onClickFunc: PropTypes.func
};

export default ButtonSearchMap;
