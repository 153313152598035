import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import { Trans, useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.brandColor};
  
  @media ${breakpoints.sm} {
    padding: 32px;
  }
`;

const Paragraph = styled.p`
  font-family: "ReadLight";
  font-size: 10px;
  margin: 0;
  padding: 8px 0;
  
  @media ${breakpoints.sm} {
    padding: 8px;
  }
  
  b {
    font-family: "ReadMedium";
    font-weight: normal;
  }
`;

const Disclaimer = () => {
  const { t } = useTranslation();
  const disclaimerParagraphs = t('disclaimerParagraph', { returnObjects: true }).map(({text}, i) =>
      <Trans t={t} key={i} components={{b: <b />, p: <Paragraph /> }}>{text}</Trans>
  );

  return (
      <Wrapper>
        <div>
          {disclaimerParagraphs}
        </div>
      </Wrapper>
  );
};

export default Disclaimer;
