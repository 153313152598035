import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {breakpoints} from "../../../commons/breakpoints";

const TitleContainer = styled.p`
  display: inline-block;
  margin: 0 0 16px;
  padding-left: 16px;
  font-family: 'ReadMedium';
  font-size: 14px;
  line-height: 20px;
  border-left: 4px solid ${props => props.theme.colors.brandColor};
  
  @media ${breakpoints.sm} {
    margin: 32px 0;
    font-size: 17px;
    line-height: 24px;
  }
`;

function Title({title}) {
  return (
      <TitleContainer className='form_title'>{title}</TitleContainer>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired
};

export default Title;
